<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routePending }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        PENDING
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeCompleted }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
      >
        COMPLETED
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
        <router-view />
    </b-card>
  </div>
</template>

<script>
export default {
  components: {

  },
};
</script>